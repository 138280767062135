@import "@sx-core/src/scss/element/accordion.scss";

.accordion-item {
    border-color: $color-grey-400;

    &__header {
        padding: pxToRem(32) pxToRem(72) pxToRem(32) 0;
        @include getFigmaStyle('Desktop/LG/Medium/Default');

        &::after {
            top: calc(50% - 28px);
            width: 56px;
            height: 56px;
            background-image: inline-svg('plus-lg', $color-black);
            background-size: 32px;
        }
    }

    &__body {
        padding-bottom: pxToRem(36);
    }

    &__image {
        margin-top: pxToRem(48);
    }

    &__content {
        ul:first-child {
            margin-top: 0;
        }

        ul:last-child {
            margin-bottom: 0;
        }
    }
}

.image__description {
    margin-top: pxToRem(16);
    @include getFigmaStyle('Desktop/SM/Bold/Default');
}

.accordion__searchbar {
    display: flex;
    margin-bottom: pxToRem(48);
}

@include media-breakpoint-down(sm) {
    .accordion__searchbar {
        flex-direction: column;
    }
}