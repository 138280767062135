@import "@sx-core/src/scss/element/keyvisual.scss";

$keyvisualHeight: 100vh;

.video.portrait .video__wrap {
    height: 100%;
    padding-right: calc($keyvisualHeight * 1.78);
}

.keyvisual {
    margin-top: pxToRem(136);
    overflow: initial;

    .keyvisual {
        margin-top: initial;
    }

    .splide__arrows {
        display: none;
    }

    .video__unmute {
        display: none;
    }
}

.keyvisual__empty {
    height: pxToRem(136);
}

.keyvisual__big {
    height: calc(100vh - 136px);

    .keyvisual__big {
        height: 100%;
    }

    ul {
        height: 100%;
    }
}

@media(max-width: 1300px) {
    .keyvisual__big {
        height: 100vh;
        margin-top: 0;
    }
}

@supports (height: 100svh) {
    $keyvisualHeight: 100svh;

    .video.portrait .video__wrap {
        height: 100%;
        padding-right: calc($keyvisualHeight * 1.78);
    }

    .keyvisual__big {
        height: calc(100svh - 136px);
    }

    @media(max-width: 1300px) {
        .keyvisual__big {
            height: 100svh;
        }
    }
}

/*** || Claim ***/

.claim {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    padding-bottom: 10%;
    color: $color-white;
    text-align: center;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.08);
    }

    &__content {
        z-index: 10;
        width: auto;
        height: auto;
        @include getFigmaStyle('Desktop/Xxl/Regular/Uppercase');
        text-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    }
}

/*** || Keyvisual ***/

.keyvisual-alternative {
    position: relative;
    margin-bottom: pxToRem(48);

    li.keyvisual-alternative__slide {
        margin-bottom: 0;
    }
}

.keyvisual-alternative__wrapper {
    position: relative;
    padding: 0 pxToRem(28) pxToRem(28);

    & + .image-desc {
        top: initial;
        bottom: 0;
        right: initial;
        left: 0;
        margin-left: pxToRem(8);
        margin-bottom: pxToRem(8);
    }

    @include media-breakpoint-down(md) {
        //padding: 0 pxToRem(16) pxToRem(48);
        padding: 0;
        padding-bottom: pxToRem(48);

        & + .image-desc {
            left: 20px;
        }
    }
}