@import "@sx-core/src/scss/element/intermediate.scss";

.intermediate {
    height: 100%;
}

.intermediate__image {
    position: relative;
    padding: pxToRem(28) pxToRem(28) 0;
}

.image-desc {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: pxToRem(224);
    max-width: pxToRem(260);
    //min-height: pxToRem(224);
    padding: pxToRem(56) pxToRem(12);
    margin-top: pxToRem(8);
    margin-right: pxToRem(8);
    color: $color-white;
    @include getFigmaStyle('Desktop/H5/Regular/Uppercase');
    text-align: center;
    hyphens: auto;
    overflow-wrap: break-word;
    background-color: $color-grey-700;
    border: 1px solid $color-white;
    box-shadow: 0 0 0 8px $color-grey-700;
}

@include media-breakpoint-down(md) {
    .intermediate__image {
        padding: pxToRem(48) 0 0;
    }
    .image-desc {
        width: pxToRem(164);
        min-height: pxToRem(164);
        padding: pxToRem(36) pxToRem(6);
        right: 20px;
    }
}

.intermediate--extended {
    height: auto;
    overflow: initial !important;

    .intermediate__item {
        position: sticky;
        top: 0;
        height: 100vh;
        overflow: hidden;

        &.intermediate__item--single {
            height: auto;
        }
    }
}

.intermediate__outer {
    position: relative;
    top: 0;
}

.intermediate__item:not(.intermediate__item--single) {
    .image img, .video img {
        height: 100vh;
    }
}