@import "@sx-core/src/scss/element/directions.scss";

.directions {
    .directions__form {
        padding: pxToRem(16);
        margin-bottom: pxToRem(48);
        color: $color-white;
        background-color: $color-grey-1100;
    }

    h3 {
        @include getFigmaStyle('Desktop/H5/Regular/Uppercase');
    }

    &-form__headline {
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            margin: pxToRem(16) 0;
            background-color: $color-black;
        }
    }

    &-form__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    &-form__input {
        flex-grow: 2;

        label {
            display: inline-block;
            margin-bottom: pxToRem(8);
            @include getFigmaStyle('Desktop/XS/Regular/Uppercase');
        }

        input {
            border: none;
            flex: 1;
        }
    }

    .directions__submit {
        flex-grow: 1;
        height: 56px;
        width: initial;
    }

    .directions__error {
        color: #ff6158;
    }
}