@mixin SxEditor {

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, table {
        //font-weight: 400;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
        // margin-bottom: pxToRem(8);
        // line-height: 1.2;
    }

    h5, .h5, h6, .h6 {
        //margin-bottom: pxToRem(16);
        //line-height: 1.3;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        margin-bottom: pxToRem(16);
    }

    h1, .h1 {
        @include getFigmaStyle('Desktop/H1/Regular/Uppercase');
    }

    h2, .h2 {
        @include getFigmaStyle('Desktop/H2/Regular/Uppercase');
    }

    h3, .h3 {
        @include getFigmaStyle('Desktop/H3/Medium/Default');
    }

    h4, .h4 {
        @include getFigmaStyle('Desktop/H4/Medium/Default');
    }

    h5, .h5 {
        @include getFigmaStyle('Desktop/H5/Medium/Default');
    }

    h6, .h6 {
        @include getFigmaStyle('Desktop/H6/Medium/Default');
    }

    p {
        @include getFigmaStyle('Desktop/MD/Regular/Default');
        margin-bottom: pxToRem(12);
        color: $color-grey-1100;
    }

    ul, ol, hr, table {
        margin-top: pxToRem(48);
        margin-bottom: pxToRem(48);
    }

    hr {
        // border-top: 1px solid $color-dark;
        width: pxToRem(90);
        height: pxToRem(1);
        margin: pxToRem(48) auto;
        background-color: $color-grey-1000;
    }

    .content a {
        color: $color-black;
        @include getFigmaStyle('Desktop/MD/Medium/Underline');
    }

    a {
        //text-decoration: underline;
    }

    ol {
        list-style-type: decimal;
        padding-left: pxToRem(18);
    }

    ol li {
        position: relative;
        padding-left: pxToRem(4);
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        padding-left: pxToRem(19);
        list-style-type: disc;
        column-gap: pxToRem(48);
        text-align: left;
        margin: pxToRem(36) 0;
    }

    ul li {
        position: relative;
        width: calc(50% - 24px);
        font-size: pxToRem(17);
        font-weight: 500;
        margin-bottom: pxToRem(16);

        &:last-child {
            margin-bottom: initial;
        }

        @media(max-width: 767px) {
            width: 100%;
        }
    }

    ul > li.list--singlecolumn {
        width: 100%;
    }

    //ul li:before {
    //    content: '\00a0';
    //    position: absolute;
    //    top: 0;
    //    left: -1.2rem;
    //    display: block;
    //    width: 1.2rem;
    //    height: 1.6rem;
    //    background-image: inline-svg('check', $color-grey-60);
    //    background-repeat: no-repeat;
    //    background-size: 0.8rem 0.8rem;
    //    background-position: 0 center;
    //}

    ul li::marker,
    ol li::marker {
        font-size: pxToRem(16);
        color: $color-primary;
    }

    ol ol, ul ul, ol ul, ul ol {
        margin-bottom: 0;
    }

    table {
        display: block;
        overflow-x: auto;
        text-align: left;
        width: 100%;
        margin: pxToRem(64) 0;

        tbody {
            display: table;
            overflow-x: auto;
            width: 100%;
        }
    }

    thead tr:last-child {
        border-bottom: 2px solid $color-black;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid $color-grey-200;
    }

    tr:first-child {
        border-color: $color-black;
    }

    th, td {
        padding: 8px 12px;
    }

    em, i {
        font-style: italic;
    }
}