@import "@sx-core/src/scss/base/error.base.scss";
@import "@sx-core/src/scss/element/error.scss";

.error {
    position: relative;
    z-index: 10;
    margin-top: $header-height;

    .button--link {
        @include getFigmaStyle('Desktop/MD/Medium/Underline');
    }
}