@import "@sx-core/src/scss/base/slider.base.scss";
@import "@sx-core/src/scss/element/slider.scss";

/* Liebe Mitarbeitende mit A am anfang, wir haben nette Beispiele für euch*/
//@import "@sx-core/src/scss/element/slider-center.scss";
//@import "@sx-core/src/scss/element/slider-open-left.scss";
//@import "@sx-core/src/scss/element/slider-one-big-centered.scss";

.slider-lg, .slider-sm {
    .splide__track {
        padding: pxToRem(36) 0;
    }
}

.slider-lg {
    .teaser-lg-item {
        display: block;
        transition: all .3s ease;

        &__headline-wrapper {
            transition: all .3s ease;
        }
    }

    .splide__slide:not(.is-active) {
        .teaser-lg-item {
            &__body {
                opacity: 0;
            }

            &__image {
                @include getFigmaStyle('Shadow/SM');
                transition: all .3s ease;

                @include media-breakpoint-up(md) {
                    &:hover {
                        box-shadow: 0 0 36px 0 rgba($color-black, .2);
                    }
                }
            }
        }
    }

    .splide__slide.is-active {
        .teaser-lg-item {
            @include getFigmaStyle('Shadow/SM');

            @include media-breakpoint-up(md) {
                &:hover {
                    box-shadow: 0 0 36px 0 rgba($color-black, .2);
                }

                &:hover .teaser-lg-item__headline-wrapper {
                    bottom: pxToRem(96);
                }
            }

            &__body {
                opacity: 1;
            }
        }
    }
}

.splide__pagination {
    display: none;
}

.splide__arrow {
    width: 72px;
    height: 72px;
    background: $color-black-alpha-500;
    background-position: center;
    background-size: 48px;
    background-repeat: no-repeat;
    border-radius: 50%;
    transition: all .3s ease;

    &--prev {
        left: pxToRem(28);
        background-image: inline-svg('arrow-lg-left', $color-white);
    }

    &--next {
        right: pxToRem(28);
        background-image: inline-svg('arrow-lg-right', $color-white);
    }

    &:hover, &:focus {
        background-color: $color-primary-800;
    }
}

@include media-breakpoint-down(md) {
    .splide__arrow {
        width: 36px;
        height: 36px;
        background-size: 12px;

        &--prev {
            left: pxToRem(20);
            background-image: inline-svg('arrow-sm-left', $color-white);
        }

        &--next {
            right: pxToRem(20);
            background-image: inline-svg('arrow-sm-right', $color-white);
        }
    }

    .slider__item {
        padding: initial !important;
    }
}