@import "@sx-core/src/scss/element/downloads.scss";

ul.downloads__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    column-count: initial;
    column-gap: pxToRem(28);

    li {
        width: auto;
        a {
            position: relative;
            display: inline-flex;
            align-items: center;
            text-decoration: underline;

            &::before {
                content: '';
                display: inline-block;
                width: 28px;
                height: 28px;
                margin-right: pxToRem(12);
                background-color: $color-primary-800;
                background-image: inline-svg('arrow-sm-right', $color-white);
                background-position: center;
                background-size: 20px;
                background-repeat: no-repeat;
                border-radius: 50%;
            }
        }
    }
}