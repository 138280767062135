.eventdate.eventdate--is-selected {
    margin: pxToRem(40) 0;
}

.eventdate {
    span {
        @include getFigmaStyle('Desktop/MD/Bold/Default');
    }
}

.eventseries {
    margin-top: pxToRem(64);
    row-gap: pxToRem(16);
    justify-content: center;
}

.eventseries__eventdate {
    .eventdate.eventdate--is-selected {
        display: none;
        margin: initial;
        color: $color-white;
        background-color: $color-black;
    }

    .eventdate {
        padding: pxToRem(6) pxToRem(12);
        margin: 0 pxToRem(8);
        border: 1px solid $color-black;
        transition: all .3s ease;

        @include media-breakpoint-up(lg) {
            &:hover, &:focus {
                color: $color-white;
                background-color: $color-black;
            }
        }
    }
}

.calendar-group__teaser > .grid {
    row-gap: pxToRem(48);
}

/*** Eventlabel ***/

.eventlabel {
    position: absolute;
    top: 0;
    right: 0;
    padding: pxToRem(10) pxToRem(24);
    color: $color-white;
    @include getFigmaStyle('Desktop/LG/Medium/Default');
    background-color: #46518A;

    &--konzert {
        background-color: #A67BC8;
    }

    &--lesung {
        background-color: #ABB379;
    }

    &--party {
        background-color: #BC6B88;
    }

    &--buesum {
        background-color: #46518A;
    }

    &--schnuesch {
        background-color: #42364C;
    }

    &--fooddrinks {
        background-color: #C89769;
    }
}

@include media-breakpoint-up(md) {
    .keyvisual-alternative .eventlabel {
        right: pxToRem(28);
    }
}