@import "../icon/sass-inline-svg";

$checkbox-font-size: $base-font-size !default;
$checkbox-check-size: $base-font-size - 5px !default;
$checkbox-width: $base-font-size !default;
$checkbox-height: $base-font-size !default;

/**
 * Checkbox container
 */

.checkbox {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkbox input[type="checkbox"] {
    display: none;
}

.checkbox > label {
    cursor: pointer;
    user-select: none;
    display: flex;

    padding-top: calc($checkbox-height / 6);
    //padding-bottom: calc($checkbox-height / 6);
    margin-bottom: pxToRem(28);
    //line-height: 1.2;
    font-size: $checkbox-font-size;
}

.checkbox.checkbox--small > label {
    font-size: $checkbox-font-size * 0.925;
}

.checkbox.checkbox--large > label {
    font-size: $checkbox-font-size * 1.125;
}

/**
 * Checkbox pseudo checkbox
 */

.checkbox > label input[type="checkbox"] {
    -webkit-appearance: none;
    flex: 0 0 auto;
    display: block;
    border: 1px solid $color-black;
    border-radius: 4px;
    //width: $checkbox-width;
    //height: $checkbox-height;
    width: pxToRem(28);
    height: pxToRem(28);
    color: $color-white;
    @include getFigmaStyle('Desktop/MD/Regular/Default');
    //background-color: $color-white;
    background-color: $color-white;
    //background-size: $checkbox-check-size $checkbox-check-size;
    background-size: pxToRem(16) pxToRem(16);
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('box', $color-white);
    transition: background-color 0.1s, border 0.1s, box-shadow .3s;
    //margin-right: $checkbox-width*0.5;
    margin-right: pxToRem(16);
    //margin-top: $checkbox-width*0.25;
}

.checkbox > label input[type="checkbox"]:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.checkbox > label:hover input[type="checkbox"],
.checkbox > label input[type="checkbox"]:focus,
.checkbox > label input[type="checkbox"]:checked {
    border-color: $color-black;
}

.checkbox > label:hover input[type="checkbox"],
.checkbox > label input[type="checkbox"]:focus {
    box-shadow: 0 0 0 2px rgba($color-black, .14);
}

.checkbox > label input[type="checkbox"]:checked {
    background-color: $color-white;
    background-image: inline-svg('box', $color-black);
}

.checkbox.checkbox--small > label input[type="checkbox"] {
    font-size: $checkbox-check-size * 0.925;
    width: $checkbox-width * 0.925;
    height: $checkbox-height * 0.925;
}

.checkbox.checkbox--large > label input[type="checkbox"] {
    font-size: $checkbox-check-size * 1.125;
    width: $checkbox-width * 1.125;
    height: $checkbox-height * 1.125;
}

.formular-item__checkbox {
    padding-top: $checkbox-font-size*2
}

.col--12-12\@xs .formular-item__checkbox,
.col--12-12\@sm .formular-item__checkbox,
.col--12-12\@md .formular-item__checkbox,
.col--12-12\@lg .formular-item__checkbox {
    padding-top: $checkbox-font-size*0.25;
}

.formular__requiredtext {
    @include getFigmaStyle('Desktop/MD/Bold/Default');
    margin-top: $checkbox-font-size;
}

.formular-item--checkbox .fomular-item__error {
    margin-left: $checkbox-width *1.5
}

/********/
