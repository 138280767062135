@import "@sx-core/src/scss/element/nav.scss";

.burger {
    top: initial;
    right: initial;
    z-index: 99999;

    .hamburger:hover {
        opacity: 1;
    }

    .hamburger {
        padding: 12px 12px;
    }

    .hamburger-box {
        width: 24px;
    }

    .hamburger-inner {
        width: 24px;
        height: 2px;
        background-color: $color-white;
        transition: background-color .3s ease;

        &::before, &::after {
            width: 24px;
            height: 2px;
            background-color: $color-white;
        }

        &::before {
            top: -7px;
        }

        &::after {
            bottom: -7px;
        }
    }
}

.js-nav-open .burger {
    .hamburger-inner {
        background-color: $color-white;

        &::before, &::after {
            background-color: $color-white;
        }
    }
}

.plus {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 100;
    transition: transform .4s ease
}

.plus:before {
    transition: all .6s ease;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background-image: inline-svg('plus-md', $color-white);
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
}

@media(max-width: 1300px) {
    .burger {
        display: block;
    }

    .js-nav-open {
        overflow: hidden;
    }

    .js-subnav-open .plus {
        transform: rotate(-45deg);
    }
}

.header-grid {
    justify-content: space-between;
}

.header__navigation {
    display: flex;
    align-items: center;
    //flex: 1;
    height: 100%;

    a {
        white-space: nowrap;
    }

    & > ul {
        height: 100%;
    }

    & > ul > li.level-0 {
        display: flex;
        align-items: center;

        &.has-children {
            background-image: inline-svg('tip', transparent);
            background-position: bottom center;
            background-size: 26px 12px;
            background-repeat: no-repeat;
            transition: background-image .3s ease;
        }

        @media(min-width: 1300px) {
            &.has-children:hover {
                background-image: inline-svg('tip', black);
            }
        }

        & > a {
            //display: flex;
            //align-items: center;
            //height: 100%;
            display: inline-block;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            padding: 0;
            transition: all .3s ease;
        }

        @media(min-width: 1300px) {
            &:hover > a {
                border-color: $color-primary-800;
                padding: pxToRem(24) 0;
            }
        }

        & > ul {
            position: absolute;
            top: 136px;
            //left: -60px;
            left: 0;
            z-index: 10;
            display: flex;
            align-items: flex-start;
            column-gap: pxToRem(28);
            width: 100vw;
            padding: pxToRem(64) pxToRem(64) pxToRem(124);
            background-color: $color-grey-1100;
            @include getFigmaStyle('Shadow/LG');
            overflow: hidden;
        }
    }

    ul {
        display: flex;
        flex: 1;
        align-items: center;
        //width: 100%;
        width: auto;
        margin: initial;
    }

    ul ul {
        position: initial;
    }

    ul > li {
        padding: initial;
        position: initial;
        height: 100%;
    }

    ul > li.level-0 {
        padding: 0 pxToRem(24);

        &.first {
            padding-left: 0;
        }

        & > a {
            @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
        }
    }

    & > ul > li ul > li > a {
        color: $color-white;
    }

    ul > li.level-1 {
        flex: 1;
        //text-align: center;
    }

    ul > li.level-1 > ul {
        //text-align: center;
    }

    ul > li.level-1:not(.menu-overview) > a, .menu-item__headline {
        display: inline-block;
        width: 100%;
        padding: pxToRem(24) 0 pxToRem(12);
        margin-bottom: pxToRem(16);
        color: $color-white;
        @include getFigmaStyle('Desktop/H4/Regular/Uppercase');
    }

    ul > li.level-2 {
        border-bottom: 1px solid $color-grey-1200;
        transition: all .3s ease;

        & > a {
            display: inline-block;
            width: 100%;
            padding: pxToRem(16) 0;
            @include getFigmaStyle('Desktop/MD/Medium/Default');
        }

        @media(min-width: 1300px) {
            &:hover {
                border-color: $color-primary-800;
            }
        }
    }

    & > ul > li.level-0 > ul {
        &::after {
            content: '';
            position: relative;
            width: 25%;
        }
    }

    .menu-image {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
        width: 25%;
        height: 100%;

        .image {
            height: 100%;
        }
    }
}

.header__link--hotels {
    position: relative;
    color: $color-grey-600;
    @include getFigmaStyle('Desktop/SM/Regular/Default');

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 0;
        height: 1px;
        background-color: $color-grey-600;
        transition: all .3s ease;
    }

    @media(min-width: 1300px) {
        &:hover::after {
            width: calc(100% - 48px);
        }
    }

    @media(max-width: 1550px) {
        display: none;
    }
}

.header-link__hotels--mobile.button {
    display: none;
}

.header__contact {
    display: none;
    color: $color-white;
    text-align: center;
    @include getFigmaStyle('Mobile / SM / Regular / Default');

    .contact__item:not(:first-child)::before {
        content: none;
    }

    .contact-title {
        @include getFigmaStyle('Mobile/MD/Bold/Default');
        margin-bottom: pxToRem(8);
    }
}

.header__ctas {
    .button {
        padding: pxToRem(8) pxToRem(10);

        &:not(:first-child) {
            border-left: none;
        }
    }
}

.js-nav-scrolled {
    .header-grid {
        justify-content: space-between;
    }

    .header__navigation {
        flex: initial;
        width: auto;

        ul {
            justify-content: flex-start;
        }

        ul > li a {
            color: $color-black;
        }

        ul > li.level-0 > ul {
            top: 88px;
        }

        & > ul > li.level-0 {
            @media(min-width: 1300px) {
                &:hover > a {
                    padding: pxToRem(8) 0;
                }
            }
        }

        ul > li ul > li > a {
            color: $color-white;
        }
    }

    .burger {
        .hamburger-inner {
            background-color: $color-white;

            &::before, &::after {
                background-color: $color-white;
            }
        }
    }
}

.header__language--mobile, .logo--scrolled {
    display: none;
}

.header__language--mobile {
    span.language__item {
        display: inline-block;
        padding: pxToRem(6) pxToRem(12);
        border: 1px solid $color-white;
    }

    span.language__item:not(.language__item--current) {
        color: $color-white;
    }

    .language__item--current {
        background-color: $color-white;
    }
}

.header__mobile-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    row-gap: pxToRem(36);
    width: 100%;
    margin-top: auto;

    .header__buttons {
        position: relative;
        left: 0;
        transform: initial;
        width: 100%;
        padding: initial;

        a {
            color: $color-black;
            background-color: $color-white;
        }
    }

    .header__booking--mobile {
        display: flex;
    }

    .header__language--mobile a {
        @include getFigmaStyle('Mobile/MD/Regular/Uppercase');
        color: $color-black;
        background-color: $color-white;
    }
}

@media(min-width: 1300px) {
    .header__navigation ul ul {
        top: 44px;

        &::before {
            top: -48px;
            height: 50px;
        }
    }
}

@media(max-width: 1300px) {
    //.burger {
    //    position: absolute;
    //    top: -11px;
    //    left: 16px;
    //}

    .menu-image {
        display: none;
    }

    .header__navigation > ul > li.level-0 > ul::after {
        content: none;
    }

    .js-nav-open {
        overflow: hidden;
    }

    .header__navigation .header__buttons .header__booking--mobile {
        display: none;
    }

    .header__mobile-menu {
        display: flex;
    }

    .js-nav-scrolled .header__mobile-menu .header__language--mobile a {
        color: $color-black;
    }

    .header__navigation {
        position: fixed;
        z-index: 550;
        top: 0;
        //left: -100vw;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        row-gap: pxToRem(48);
        width: 100%;
        min-height: 100vh;
        transform: translateY(-100%);
        padding: pxToRem(28) pxToRem(28) pxToRem(48);
        background-color: $color-primary-800;
        -ms-overflow-style: scrollbar;
        overflow-style: scrollbar;
        overflow: auto;
        transition: all .6s ease;
        opacity: 0;

        ul {
            flex: initial;
            flex-direction: column;
            align-items: initial;
            justify-content: flex-start;
            height: auto;
            width: 100%;
        }

        ul ul {
            opacity: 1;
        }

        ul li {
            text-align: left;
        }

        ul > li.level-0 {
            margin: initial;
            padding: initial;

            & > ul {
                display: none;
                position: relative;
                top: 0 !important;
                align-items: center;
                width: 100%;
                padding: initial;
                background-color: initial;
            }
        }

        ul > li a {
            color: $color-white;
        }

        ul ul {
            display: block;
            position: relative;
            top: 0;
            left: initial;
            transform: none;
        }

        ul ul li {
            padding: 0;
        }

        ul > li.level-0 {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: auto;

            & > a {
                //display: flex;
                position: initial;
                display: inline-block;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: pxToRem(28) pxToRem(28) pxToRem(4) 0;
                color: $color-white;
                //text-align: center;
                font-size: pxToRem(26);
                line-height: 1.2;

                &::before {
                    font-size: pxToRem(22);
                    line-height: 1.2;
                }

                //&::after {
                //    content: "";
                //    position: absolute;
                //    bottom: 0;
                //    left: 0;
                //    display: block;
                //    width: 100%;
                //    height: 1px;
                //    background-color: $color-white;
                //    opacity: 1;
                //    transform: none;
                //}
            }
        }

        ul > li > ul > li.menu-first {
            padding: initial;

            a {
                padding-top: pxToRem(16);
            }
        }

        ul > li > ul > li.level-1 {
            padding: initial;
            width: 100%;

            & > a {
                //padding: initial;
            }
        }

        .menu-item__headline {
            border-bottom: 1px solid $color-grey-1300;
            font-size: pxToRem(20);
        }

        ul > li > ul > li.level-1, ul ul > .menu-first {
            height: auto;

            &:not(.menu-item__headline) > a {
                display: block;
                //padding: pxToRem(24) pxToRem(28) pxToRem(2);
                font-size: pxToRem(20);
                font-weight: 500;
                color: $color-white;
                background-color: initial;

                &::before {
                    content: none;
                }
            }
        }

        ul > li.level-2 {
            border-bottom: none;
        }

        ul > li.level-2, ul > li > ul > li.level-1.menu-overview {
            a {
                padding: pxToRem(4) 0;
                font-size: pxToRem(15);
                font-weight: 400;
            }
        }

        ul > li > ul > li.level-1.menu-overview {
            padding: pxToRem(18) 0 pxToRem(4);
        }
    }

    .header__language {
        ul {
            flex-direction: row;
            align-items: center;

            & > li .language__item--current::before {
                background-color: $color-primary-800;
            }

            & > li:not(:last-child)::after {
                background-color: $color-primary-800;
            }
        }
    }

    .header__additions {
        flex-direction: column;
        row-gap: pxToRem(20);
        width: 100%;
    }

    .js-nav-open .header__navigation {
        left: 0;
        transform: translateY(0%);
        opacity: 1;
    }

    .header__language {
        display: none;
    }

    .header__language--mobile {
        display: block;
    }

    .js-nav-scrolled {
        .header__navigation {
            width: 100%;
        }

        .logo {
            display: none;
        }

        .logo--scrolled {
            display: block;
        }
    }

    .header-link__hotels--mobile.button {
        display: block;
    }

    .header__contact {
        display: block;
    }

    //@supports (min-height: 100dvh) {
    //    .header__navigation {
    //        min-height: 100dvh;
    //    }
    //}
}