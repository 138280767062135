.teaser-item-blog {
    display: block;
    background-color: $color-neutral-700;

    .button {
        width: 100%;
    }
}

.teaser-blog__item-container {
    &:not(:first-child) {
        margin-top: pxToRem(60);
    }

    &--mobile {
        &:not(:first-child) {
            margin-top: pxToRem(60);
        }
    }
}

.teaser-item-blog__headline {
    position: relative;
    padding: 0 pxToRem(16) pxToRem(16);
    font-size: pxToRem(26);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: initial;
    color: $color-white;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 90px;
        height: 1px;
        background-color: $color-primary-600;
    }
}

.teaser-item-blog__teasertext {
    position: relative;
    padding: 0 pxToRem(16);
    margin-top: pxToRem(16);
    margin-bottom: pxToRem(36);
    //font-size: 17px;
    color: $color-white;
    text-align: center;

    p:last-child {
        margin-bottom: 0 !important;
    }
}

.teaser-item-blog__eventdate {
    display: block;
    padding: pxToRem(16) 0;
    text-align: center;
    color: $color-white;
}

.teaser-blog__item-container--mobile {
    display: none;
}

@include media-breakpoint-down(lg) {
    .teaser-blog__item-container, .column1, .column2, .column3 {
        display: none;
    }

    .grid--blog {
        flex-direction: column;
    }

    .teaser-blog__item-container--mobile {
        display: flex;
        flex-direction: column;
    }
}

/*** Newsslider ***/

.slider-item-news {
    display: block;
    color: $color-white;
    background-color: $color-grey-700;

    &__body {
        padding: pxToRem(28) pxToRem(12);
        text-align: center;
    }

    &__headline {
        @include getFigmaStyle('Desktop/H5/Medium/Default');
    }

    &__label {
        margin-top: pxToRem(8);
        color: $color-grey-300;
        @include getFigmaStyle('Desktop/MD/Medium/Default');
    }
}