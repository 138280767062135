.gallery {
    margin: $modulspacing-desktop-m 0;

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: pxToRem(96);
        grid-row-gap: pxToRem(96);
    }

    &__item--image {
        position: relative;
        width: 100%;
        transition: all .3s ease;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .08);
            opacity: 0;
            transition: all .3s ease;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: pxToRem(40);
            right: pxToRem(40);
            z-index: 10;
            display: inline-block;
            width: pxToRem(36);
            height: pxToRem(36);
            background-image: inline-svg('plus-sm', $color-white);
            background-position: center;
            background-size: pxToRem(12) pxToRem(12);
            background-repeat: no-repeat;
            border-radius: 50%;
            transition: all .3s ease;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.20);

                &::before {
                    opacity: 1;
                }

                &::after {
                    background-color: $color-black-alpha-400;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .gallery {
        margin: $modulspacing-mobile-s 0;
        &__grid {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: pxToRem(48);
        }
    }
}