@import "@sx-core/src/scss/element/footer.scss";

.footer {
    height: auto;
    //padding-top: pxToRem(64);
    padding-bottom: pxToRem(64);

    & > .container {
        height: auto;
    }

    & > .container > .grid {
        row-gap: pxToRem(64);
        height: auto;
    }

    &__navigation {
        column-gap: pxToRem(36);
    }

    &__imprint {
        align-items: flex-end;
    }

    &__menu {
        display: flex;
        flex-wrap: wrap;
        row-gap: pxToRem(48);
        justify-content: space-between;
        padding: pxToRem(48) 0 0;
        border-top: 1px solid $color-grey-400;

        ul {
            display: flex;
            flex-wrap: wrap;
            row-gap: pxToRem(12);
            width: auto;
            margin: 0 pxToRem(-18);
        }

        ul > li {
            padding: 0 pxToRem(18);
        }

        ul > li a {
            position: relative;
            color: $color-black;
            font-size: pxToRem(17);
            @include getFigmaStyle('Desktop/MD/Regular/Default');

            &:hover {
                color: $color-black;
            }

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: 0;
                height: 1px;
                background-color: $color-primary-800;
                transition: all .3s ease;
            }
        }

        @include media-breakpoint-up(md) {
            ul > li:hover a::after {
                width: 100%;
            }
        }
    }
}

.contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: $color-grey-700;
    @include getFigmaStyle('Desktop/SM/Regular/Default');

    a {
        color: $color-grey-700;
    }

    &__item:not(:first-child) {
        position: relative;

        &::before {
            content: '|';
            margin: 0 pxToRem(8);
        }
    }
}

.footer__newsletter {
    &--headline {
        margin-bottom: pxToRem(28);
        @include getFigmaStyle('Desktop/H4/Regular/Uppercase');
    }

    &-form-outer {
        padding-right: pxToRem(48);
    }

    &-line {
        position: relative;
        height: 1px;
        padding-left: pxToRem(48);
        background-color: $color-grey-400;
    }
}

.footer__navigation--imprint {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: pxToRem(36);
    row-gap: pxToRem(12);
}

.footer__cookies {
    position: relative;
    display: flex;
    align-items: center;
    color: $color-black;
    @include getFigmaStyle('Desktop/MD/Regular/Default');
    transition: all .3s ease;

    span {
        position: relative;
        display: inline-block;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 0;
            height: 1px;
            background-color: $color-primary-800;
            transition: all .3s ease;
        }
    }

    &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: pxToRem(16);
        background-image: inline-svg('cookie', $color-black, transparent);
        background-position: center;
        background-size: 24px;
        background-repeat: no-repeat;
    }

    @include media-breakpoint-up(md) {
        &:hover, &:focus, &:active {
            span::after {
                width: 100%;
            }
        }
    }
}

.footer__imprint {
    display: flex;
    align-items: flex-end;

    .contact {
        justify-content: flex-start;
    }
}

.footer__logos {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    column-gap: pxToRem(48);
    row-gap: pxToRem(28);
    margin-left: auto;
}

.logo--leisure {
    height: 140px;
    width: auto;
}

.logo--sh {
    height: 96px;
    width: auto;
}

@include media-breakpoint-down(lg) {
    .footer__newsletter {
        &-form-outer {
            padding-right: 0;
        }

        &-line {
            display: none;
        }
    }

    .footer__logos {
        justify-content: center;
    }

    .footer__imprint {
        justify-content: center;
        text-align: center;

        .contact {
            justify-content: center;
            text-align: center;
        }
    }
}

@include media-breakpoint-down(md) {
    .footer__menu {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border-bottom: 1px solid $color-grey-400;
        padding-bottom: pxToRem(48);

        ul {
            justify-content: center;
        }
    }

    .footer > .container > .grid {
        row-gap: pxToRem(72);
    }

    .footer__newsletter {
        text-align: center;

        .input-group {
            flex-direction: column;
            row-gap: pxToRem(8);
        }

        .formular-item__input {
            text-align: center;
        }
    }
}