@import "@sx-core/src/scss/element/teaser.scss";

.teaser > .grid {
    row-gap: pxToRem(64);
}

@include media-breakpoint-down(md) {
    .teaser > .grid {
        row-gap: pxToRem(48);
    }
}

/*** Teaser MD ***/

.teaser-item {
    &__image {
        position: relative;
        @include getFigmaStyle('Shadow/SM');
        transition: all .3s ease;
    }

    &__body {
        margin-top: pxToRem(28);
        text-align: center;
    }

    &__headline {
        @include getFigmaStyle('Desktop/H5/Medium/Default');
    }

    &__price, &__eventdate {
        margin-top: pxToRem(8);
        color: $color-grey-700;
        @include getFigmaStyle('Desktop/MD/Medium/Default');
    }

    &__teasertext {
        margin-top: pxToRem(16);
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }

    @include media-breakpoint-up(md) {
        &:hover .teaser-item__image {
            box-shadow: 0 0 36px 0 rgba($color-black, .2);
        }
    }
}

/*** Teaser LG ***/

.teaser-lg-item {
    &__image {
        position: relative;
    }

    &__headline-wrapper {
        position: absolute;
        bottom: pxToRem(64);
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__headline {
        padding: pxToRem(24) pxToRem(36);
        color: $color-grey-300;
        @include getFigmaStyle('Desktop/XS/Bold/Uppercase');
        background-color: $color-primary-800;
    }

    &__body {
        padding: pxToRem(28) pxToRem(64);
        color: $color-white;
        text-align: center;
        background-color: $color-grey-700;
        transition: all .3s ease;
    }
}

@include media-breakpoint-down(md) {
    .teaser-lg-item {
        &__body {
            padding: pxToRem(16);
        }
    }
}

/*** Teaser SM ***/

.teaser-sm-item {
    &__image {
        @include getFigmaStyle('Shadow/SM');
        transition: all .3s ease;
    }

    &__body {
        margin-top: pxToRem(28);
        text-align: center;
    }

    &__headline {
        @include getFigmaStyle('Desktop/H5/Medium/Default');
    }

    &__price {
        margin-top: pxToRem(8);
        color: $color-grey-700;
        @include getFigmaStyle('Desktop/MD/Medium/Default');
    }

    &__teasertext {
        margin-top: pxToRem(16);
    }

    @include media-breakpoint-up(md) {
        &:hover .teaser-sm-item__image {
            box-shadow: 0 0 36px 0 rgba($color-black, .2);
        }
    }
}

/*** Teaser Event ***/

.teaser-event-upcoming {
    position: relative;
    row-gap: pxToRem(8);

    &__item {
        z-index: 2;
    }

    &::before, &::after {
        content: '';
        position: absolute;
        left: 24px;
        display: block;
        width: calc(100% - 48px);
        height: 1px;
        background-color: $color-grey-400;
    }

    &::before {
        top: 40px;
    }

    &::after {
        bottom: 40px;
    }
}

.teaser-event-item {
    &__headline, &__eventdate {
        padding: pxToRem(9) pxToRem(8);
        color: $color-white;
        @include getFigmaStyle('Desktop/SM/Medium/Default');
        text-align: center;
        background-color: $color-grey-700;
        transition: all .3s ease;
    }

    .teaser-event-item__eventdate.eventdate--is-selected {
        margin: initial;
    }

    @include media-breakpoint-up(lg) {
        &:hover, &:focus, &:active {
            .teaser-event-item__headline, .teaser-event-item__eventdate {
                background-color: $color-black;
            }
        }
    }
}

.teaser-event-upcoming__link {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .event-link {
        position: relative;
        @include getFigmaStyle('Desktop/H4/Regular/Uppercase');

        &::before {
            content: '';
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: pxToRem(8);
            background-image: inline-svg('chevron-left', $color-black, transparent);
            background-position: center;
            background-size: 20px;
            background-repeat: no-repeat;
        }
    }
}

@include media-breakpoint-down(md) {
    .teaser-event-upcoming {
        &::before, &::after {
            content: none;
        }

        &__item {
            a {
                position: relative;
                display: block;
                padding: 0 pxToRem(15);
            }

            &:first-child a {
                &::before {
                    content: '';
                    position: absolute;
                    top: 39px;
                    left: 0;
                    z-index: -1;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $color-grey-400;
                }
            }

            &:nth-child(3) a {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 39px;
                    left: 0;
                    z-index: -1;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $color-grey-400;
                }
            }
        }
    }

    .teaser-event-upcoming__link {
        justify-content: center;
        order: -1;
        margin-bottom: pxToRem(20);

        .event-link {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;

            &::before {
                margin-right: 0;
                margin-top: pxToRem(8);
                background-image: inline-svg('chevron-down', $color-black, transparent);
            }
        }
    }
}