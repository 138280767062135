@import "@sx-core/src/scss/element/header.scss";

.header {
    position: absolute;
    height: 136px;
    background-color: transparent;
    @include getFigmaStyle('Shadow/MD');

    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $color-grey-400;
    }

    &__logo {
        height: 59px;
        width: auto;

        img {
            height: 100%;
            width: auto;
        }
    }
}

.js-nav-scrolled02 .header {
    transition: all .5s ease;
}

@media(min-width: 1300px) {
    .header > .container > .grid {
        align-items: center;
    }
}

.header__ctas {
    display: none;
    align-items: center;
    margin-left: auto;
}

.js-nav-scrolled {
    .header::after {
        content: none;
    }
}

.header__logo--nav {
    display: none;
    width: pxToRem(128);
    filter: invert(92%) sepia(0%) saturate(7481%) hue-rotate(115deg) brightness(124%) contrast(101%);
}

.header__contact {
    display: none;
}

.header__language {
    display: flex;
    align-items: center;
    height: 59px;

    .language__item {
        @include getFigmaStyle('Desktop/MD/Regular/Uppercase');

        a {
            display: inline-block;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            transition: all .3s ease;
        }

        @include media-breakpoint-up(lg) {
            &:hover, &:focus {
                a {
                    padding: pxToRem(24) 0;
                    border-color: $color-black;
                }
            }
        }
    }

    ul > li {
        position: relative;
        padding: 0 pxToRem(16);
    }

    ul > li:not(:last-child)::after {
        content: '';
        position: absolute;
        top: calc(50% - 40px);
        right: 0;
        width: 1px;
        height: 80px;
        background-color: $color-grey-200;
    }
}

@include media-breakpoint-up(lg) {
    .js-nav-scrolled {
        .header {
            height: 88px;
            background-color: $color-white-alpha-1200;
            -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
        }

        .header__logo {
            height: 48px;
        }

        .header__language {
            ul > li:not(:last-child)::after {
                top: calc(50% - 24px);
                height: 48px;
            }

            .language__item {
                color: $color-black;
                @include media-breakpoint-up(lg) {
                    &:hover, &:focus {
                        a {
                            padding: pxToRem(8) 0;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 1300px)  {
    .header {
        height: 176px;
        padding-top: pxToRem(16);
        background: $color-image-verlauf;
        box-shadow: none;

        &::after {
            content: none;
        }

        &__logo {
            margin: pxToRem(12) auto 0;
            filter: invert(92%) sepia(0%) saturate(7481%) hue-rotate(115deg) brightness(124%) contrast(101%);
        }
    }

    .js-nav-scrolled02 .header {
        transform: initial;
    }
    //
    //.js-nav-scrolled .header {
    //    //position: fixed;
    //    transition: all 1s ease;
    //}

    .js-nav-scrolled {
        .header {
            padding: pxToRem(14) 0;
            height: 76px;
            background-color: $color-primary-800;
            transform: translateY(-100%);

            &__logo {
                height: 48px;

                img {
                    height: 48px;
                }
            }
        }

        .header__ctas {
            display: flex;
            height: 100%;
        }

        .header__logo {
            display: none;
        }
    }

    .js-nav-open .header__logo.header__logo--nav {
        width: pxToRem(128);
        height: auto;
        margin-top: 0;

        img {
            width: pxToRem(128);
            height: auto;
        }
    }

    .js-nav-scrolled02 {
        .header {
            position: fixed;
            transform: translateY(0);
            transition: all 1s ease;
        }
    }

    .header__logo--nav {
        display: block;
    }

    .header__contact {
        display: block;
    }

    .header.header--kv-alternative {
        background: none;

        .header__logo {
            filter: none;
        }

        .burger .hamburger-inner {
            background-color: $color-black;

            &::before, &::after {
                background-color: $color-black;
            }
        }
    }

    .js-nav-scrolled02 .header.header--kv-alternative, .js-nav-open .header.header--kv-alternative {
        background-color: $color-black;

        .burger .hamburger-inner {
            background-color: $color-white;

            &::before, &::after {
                background-color: $color-white;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .header {
        &__logo {
            height: 43px;

            img {
                height: 43px;
            }
        }
    }
}