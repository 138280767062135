
* {
    outline: none !important;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $base-font-size;
    font-variant-numeric: normal;
    font-feature-settings: normal;
    line-height: 1.5;
}

body {
    overflow-x: hidden;
    font-family: $font-family-primary;
    font-weight: 400;
    color: $font-color;
}

.lightbeam {
    position: fixed;
    bottom: -5vh;
    left: -5vw;
    display: block;
    height: 150vh;
    width: 105vw;
    clip-path: polygon(100% 0, 0% 100%, 100% 50%);
    background-color: $color-grey-100;
}

#messengerpeople-widget-83725ed8e4ee1d7217\.23442458 {
    transition: all .3s ease;

    .\32 \-83725ed8e4ee1d7217\.23442458-13 {
        background-color: $color-black;

        &:hover, &:focus {
            svg {
                fill: $color-white;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    #messengerpeople-widget-83725ed8e4ee1d7217\.23442458 {
        opacity: 0;
        pointer-events: none;
    }

    .js-nav-scrolled #messengerpeople-widget-83725ed8e4ee1d7217\.23442458 {
        opacity: 1;
        pointer-events: auto;
    }
}

#ot-widget-container4 {
    iframe {
        min-height: 320px;
    }
}

body.variant--white {
    &:not(.js-nav-scrolled) {
        .header__logo {
            filter: invert(92%) sepia(0%) saturate(7481%) hue-rotate(115deg) brightness(124%) contrast(101%);
        }
    }

    &:not(.js-nav-scrolled) .header__navigation ul > li.level-0 a, &:not(.js-nav-scrolled) .header__link--hotels, p, .footer a, &:not(.js-nav-scrolled) .language__item > a {
        color: $color-white;
    }

    &:not(.js-nav-scrolled, .js-nav-open) {
        .header__navigation > ul > li.level-0:hover > a, .header__language .language__item:hover a, .header__language .language__item:focus a {
            border-color: $color-white;
        }

        .burger .hamburger-inner {
            background-color: $color-white;
            &::before, &::after {
                background-color: $color-white;
            }
        }
    }

    &.js-nav-open {
        span.language__item--current {
            color: $color-black;
        }
    }

    ul.downloads__items li a {
        color: $color-white;
    }

    .footer__menu ul > li a {
        &:hover, &:focus {
            color: $color-white;
        }

        &::after {
            background-color: $color-white;
        }
    }
}

.varianttheme--schnuesch {
    color: $color-white;

    &::after {
        content: "";
        position: fixed;
        top: 0;
        height: 100vh;
        left: 0;
        right: 0;
        z-index: -1;
        background-image: url('/assets/img/bg-schnuesch.jpg');
        background-color: $color-black;
        background-position: center;
        background-size: cover;
    }

    .lightbeam {
        display: none;
    }

    .footer__cookies span::after {
        background-color: $color-white;
    }
}

.css-transitions-only-after-page-load *:not(img,picture) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.wrapper {
    visibility: visible !important;
}

a {
    color: $color-primary;
    text-decoration: none;
}

ins {
    text-decoration: none;
}

.modulspacing--md {
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;

    @include media-breakpoint-down(md) {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;
    }
}

.text-center {
    text-align: center;
}

h1, .h1 {
    @include getFigmaStyle('Desktop/H1/Regular/Uppercase');
}

.content {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        margin-top: pxToRem(48);
    }
}

.editor ul > li.list--singlecolumn {
    width: 100%;
}

.modulspacing {
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;
    @include media-breakpoint-down(md) {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;
    }
}

@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        hyphens: auto;
    }

    .editor ul > li.list--doublecolumn {
        width: 100%;
    }
}