@import "@sx-core/src/scss/element/calendar.scss";

.mbsc-ios.mbsc-calendar-button.mbsc-button, .mbsc-ios.mbsc-range-control-value.active, .mbsc-ios.mbsc-calendar-week-nr, .mbsc-ios.mbsc-calendar-today {
    color: $color-primary-800;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    border-color: $color-grey-700;
    background: $color-grey-700;
}

.mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
    background: $color-primary-800;
    border-color: $color-primary-800;
    color: $color-white;
}

.mbsc-ios.mbsc-range-day::after {
    background-color: $color-grey-300;
}

/*** Events ***/

.calendar {
    &__daterange {
        max-width: 480px;
        padding: pxToRem(16);
        margin: pxToRem(96) auto;
        background-color: $color-grey-1100;
    }

    &-daterange__input:not(:first-child) {
        padding-left: pxToRem(4);
    }

    .grid {
        row-gap: pxToRem(16);
    }

    label {
        display: inline-block;
        margin-bottom: pxToRem(8);
        color: $color-white;
        @include getFigmaStyle('Desktop/XS/Regular/Uppercase');
    }

    input {
        background-image: inline-svg('angle-down-filled', $color-black);
        background-position: right pxToRem(12) center;
        background-size: pxToRem(28);
        background-repeat: no-repeat;
    }
}

.calendar-group {
    &__title {
        text-align: center;
    }
}