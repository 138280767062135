.stoerer {
    position: absolute;
    max-width: 420px;
    width: auto;
    height: auto;
    z-index: 5;
    transition: all .3s ease;

    @include media-breakpoint-up(lg) {
        &:hover, &:focus {
            transform: scale(1.02);
        }
    }
}

.stoerer--1 {
    top: pxToRem(72);
    right: pxToRem(72);
}

.stoerer--2 {
    left: 100px;
    bottom: 300px;
}

@media(max-width: 1300px) {
    .stoerer--1 {
        top: 176px;
        right: 6%;
    }

    .stoerer--2 {
        left: 6%;
    }
}

@include media-breakpoint-down(lg) {
    .stoerer--2 {
        bottom: 150px;
    }
}

@include media-breakpoint-down(md) {
    .stoerer {
        max-width: 220px;
    }
}