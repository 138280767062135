@import "@sx-core/src/scss/element/article.scss";

.header + .article {
    margin-top: pxToRem(136) + pxToRem(96);

    @include media-breakpoint-down(md) {
        margin-top: pxToRem(80) + pxToRem(56);
    }
}

.article {
    text-align: center;

    .headline {
        margin-bottom: pxToRem(48);
    }

    .headline--topline {
        margin-bottom: pxToRem(20);
        @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
    }

    .price {
        margin-top: pxToRem(40);
        @include getFigmaStyle('Desktop/MD/Bold/Default');
    }
}

.header--kv-alternative + .logo--gastro {
    margin: pxToRem(200) auto pxToRem(48);
}

.logo--gastro {
    display: block;
    width: 205px;
    height: auto;
    margin: pxToRem(56) auto;
}

.logo--gastro + .article {
    margin-top: pxToRem(56);
}

body.pageid-5c13acd0-e9a0-4a73-b0f4-4b3286772961 {
    color: $color-white;
    //background-image: url('/assets/img/bg-jeans.jpg');
    //background-color: #263584;
    //background-position: center;
    //background-attachment: fixed;

    .lightbeam {
        display: none;
    }

    &::after {
        content: "";
        position: fixed;
        top: 0;
        height: 100vh;
        left: 0;
        right: 0;
        z-index: -1;
        background-image: url('/assets/img/bg-jeans.jpg');
        background-color: #263584;
        background-position: center;
        background-size: cover;
    }
}

body.pageid-5c13a9b7-5db4-4613-a4ae-2d8886772961 {
    //background-image: url("/assets/img/bg-kariert.png");
    //background-color: #FFFFFF;
    //background-position: center;
    //background-attachment: fixed;
    //background-size: cover;

    .lightbeam {
        display: none;
    }

    &::after {
        content: "";
        position: fixed;
        top: 0;
        height: 100vh;
        left: 0;
        right: 0;
        z-index: -1;
        background-image: url("/assets/img/bg-kariert.png");
        opacity: .6;
        background-position: center;
        background-size: cover;
    }
}

