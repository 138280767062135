.booking-bar {
    position: absolute;
    bottom: 96px;
    width: 100%;
    z-index: 10;

    &__inner {
        position: relative;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 80px;
        column-gap: pxToRem(2);
        background-color: transparent;
        //transition: all .5s ease;
        //transition: background-color 0s ease;
    }

    .chosen-pseudo:hover,
    .chosen-pseudo:focus,
    .chosen-container .chosen-single:hover,
    .chosen-container .chosen-choices:hover,
    .chosen-container.chosen-with-drop .chosen-single,
    .chosen-container.chosen-container-active .chosen-single,
    .chosen-container.chosen-with-drop .chosen-choices,
    .chosen-container.chosen-container-active .chosen-choices, .input, .chosen-pseudo,
    .chosen-container .chosen-single,
    .chosen-container .chosen-choices, .input {
        box-shadow: none;
    }
}

.showBookingbar {
    display: flex;
}

.booking-bar__input {
    flex: 1 1 auto;
    max-width: 300px;
    height: 72px;
    transition: all .3s ease;

    @include getFigmaStyle('Shadow/MD');

    input {
        width: 100%;
        height: 100%;

        &::placeholder {
            color: $color-black;
        }

        &:hover, &:focus {
            border: none;
        }
    }

    .chosen-container .chosen-single {
        padding: 0 pxToRem(40) 0 pxToRem(24);
    }

    .chosen-container, .chosen-single, .daterange {
        height: 100%;
    }

    .chosen-container .chosen-drop {
        //@include getFigmaStyle('Shadow/MD');
    }

    &--daterange {
        input {
            border: none;
            padding-left: pxToRem(52);
            background-image: inline-svg('calendar-2', $color-black, transparent);
            background-size: 28px;
            background-position: 12px center;
            background-repeat: no-repeat;
        }
    }

    &--guests {
        max-width: 200px;

        .chosen-container .chosen-drop {
            border: initial;
            @include getFigmaStyle('Shadow/SM');
        }

        .chosen-pseudo:hover, .chosen-pseudo:focus, .chosen-container .chosen-single:hover, .chosen-container .chosen-choices:hover, .chosen-container.chosen-with-drop .chosen-single, .chosen-container.chosen-container-active .chosen-single, .chosen-container.chosen-with-drop .chosen-choices, .chosen-container.chosen-container-active .chosen-choices {
            //border: 1px solid $color-secondary-100;
        }
    }

    select {
        appearance: none;
        -webkit-appearance: none;
        width: 100%;
        height: 100%;
        padding: 0 pxToRem(12);
        padding-right: pxToRem(36);
        color: $color-black !important;
        //background-image: inline-svg('angle-down-filled', $color-black);
        background-size: 24px;
        background-color: $color-grey-100;
        background-position: right pxToRem(6) top 50%;
        background-repeat: no-repeat;

        &::placeholder {
            color: $color-black !important;
        }
    }

    &--bookingbtn {
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
        align-items: center;

        .button--lg {
            flex: 1 0 auto;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: pxToRem(19);
        }
    }
}

.booking-bar__mobile {
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 10;
    transform: translateX(-50%);
    display: inline-flex;
    height: 72px;
    width: 100%;
    max-width: calc(100% - 40px);

    .button {
        flex: 1;
    }

    .button:first-child {
        opacity: .8;
    }
}

@include media-breakpoint-up(lg) {
    .booking-bar__input {
        &:hover {
            height: 100%;
        }
    }

    .booking-bar__mobile {
        display: none;
    }

    .booking-bar__input .chosen-container .chosen-results .disabled-result {
        display: none;
    }
}

.booking-bar-scrolled {
    .booking-bar__inner {
        position: fixed;
        //top: 64px;
        top: 88px;
        left: 0;
        min-height: initial;
        height: 70px;
        background-color: rgba($color-grey-700, .3);
        transition: background-color .5s ease-in, top .5s ease;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
    }

    .booking-bar__input {
        height: 36px;
        transition: none;

        .chosen-container .chosen-single {
            min-height: initial;
        }

        .button {
            padding: initial;
        }
    }

    .booking-bar__mobile {
        display: none;
    }
}

@media(max-width: 1300px) {
    .booking-bar-scrolled .booking-bar__inner {
        display: none;
    }
}

@include media-breakpoint-down(lg) {
    .booking-bar {
        bottom: 20px;
        z-index: 20;
    }

    .booking-bar__inner {
        z-index: 20;
        display: none;
        flex-direction: column;
        margin: auto;
        min-height: initial;
        max-width: 80%;
        padding: pxToRem(16) pxToRem(16) 0;
        background-color: $color-black-alpha-1200;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(14px);
    }

    .js-nav-scrolled .booking-bar__inner {
        display: none;
    }

    .booking-bar__input {
        max-width: 100%;
        &:not(.booking-bar__input--bookingbtn) {
            margin-bottom: pxToRem(2);
        }
    }
}

@include media-breakpoint-down(sm) {
    .booking-bar__inner {
        max-width: 100%;
    }

    .booking-bar__mobile {
        width: 100%;
    }
}

/*** || Booking-Bar Zimmer ***/

.booking-bar--room {
    margin-top: pxToRem(48);
    margin-bottom: $modulspacing-desktop-m;

    input:not([name='bookingcode']):not([type='radio']), select {
        @include getFigmaStyle('Shadow/SM');
        transition: all .15s ease;
        &:hover, &:focus {
            @include getFigmaStyle('Shadow/MD');
        }
    }

    .label__container, .fb-room__label {
        display: none;
    }

    .fb-room__input-wrap, #room-adults .fb-room__select {
        width: 100%;
        height: 100%;
        padding-right: pxToRem(4);

        input, select {
            width: 100%;
            height: 100%;
            background-color: $color-grey-100;
            border: initial;
        }
    }

    #fbPickerDateFromRoom, #fbPickerDateToRoom {
        padding-left: pxToRem(52);
        background-image: inline-svg('calendar-2', $color-black, transparent), inline-svg('angle-down-filled', $color-black);
        background-position: left pxToRem(12) center, right pxToRem(12) center;
        background-size: 28px;
        background-repeat: no-repeat;
    }

    #room-adults select {
        -webkit-appearance: none;
        appearance: none;
        color: $color-black;
        padding-left: pxToRem(24);
        background-image: inline-svg('angle-down-filled', $color-black);
        background-position: right pxToRem(12) center;
        background-size: 28px;
        background-repeat: no-repeat;
    }

    .room__booking--booknow {
        display: flex;
    }

    .fb-room__item-outer {
        justify-content: space-between;
    }

    .fb-room__input-outer--bottom {
        margin-top: pxToRem(28);

        .fb-room__item-outer {
            column-gap: pxToRem(48);
            row-gap: pxToRem(28);
            padding: 0 pxToRem(48);
        }
    }

    #room-bookingcode {

        @include media-breakpoint-up(lg) {
            margin-right: auto;
        }

        .fb-room__select {
            border-radius: 0;
        }

        input {
            -webkit-appearance: none;
            appearance: none;
            min-width: 280px;
            padding: pxToRem(6) pxToRem(12);
            border: initial;
            border-bottom: 1px solid $color-black;
            border-radius: 0;
            color: $color-black;
            @include getFigmaStyle('Desktop/SM/Regular/Default');
            background-color: transparent;

            &::placeholder {
                color: $color-black;
            }
        }
    }

    .fb-room__item--radios {
        //display: grid;
        //grid-template-columns: 2fr;
        //row-gap: pxToRem(8);

        .fb-room__item {
            display: flex;
            //column-gap: pxToRem(48);
            &:not(:first-child) {
                margin-top: pxToRem(8);
            }
        }

        .radio-item__container {
            display: flex;
            //flex-wrap: wrap;
            column-gap: pxToRem(16);
            align-items: center;
            //height: pxToRem(28);
            flex: 0 0 50%;

            input[type="radio"] {
                -webkit-appearance: none;
                appearance: none;
                display: grid;
                place-content: center;
                min-width: pxToRem(28);
                width: pxToRem(28);
                height: pxToRem(28);
                color: $color-black;
                border: 1px solid $color-black;
                border-radius: 50%;

                &::before {
                    content: '';
                    width: pxToRem(16);
                    height: pxToRem(16);
                    border-radius: 50%;
                    transform: scale(0);
                    box-shadow: inset pxToRem(16) pxToRem(16) $color-black;
                }
            }

            input[type="radio"]:checked::before {
                transform: scale(1);
            }
        }
    }

    .room__booking--container {
        margin: 0 auto;
    }
}

@include media-breakpoint-down(lg) {
    .booking-bar--room {
        #fbPickerDateFromRoom, #fbPickerDateToRoom, #personen {
            min-height: pxToRem(72);
        }

        .fb-room__input-wrap, #room-adults .fb-room__select {
            padding-right: initial;
        }

        .fb-room__input-outer--bottom {
            .fb-room__item-outer {
                padding: 0;
            }
        }
    }

    .fb-room__input-outer--bottom .fb-room__item-outer {
        flex-direction: column;
        align-items: center;
    }
}

@include media-breakpoint-up(md) {
    .fb-room__item--radios {
        min-width: pxToRem(400);
    }
}

@include media-breakpoint-down(md) {
    .booking-bar--room {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;

        #room-bookingcode {
            width: 100%;

            .fb-room__select input {
                width: 100%;
            }
        }

        .fb-room__item--radios {
            width: 100%;
        }

        .fb-room__item--radios .fb-room__item {
            row-gap: pxToRem(16);

            &:not(:first-child) {
                margin-top: pxToRem(16);
            }
        }

        #fbPickerDateFromRoom, #fbPickerDateToRoom {
            padding: 0 pxToRem(36) 0 pxToRem(24);
            background-image: inline-svg('angle-down-filled', $color-black);
            background-position: right pxToRem(12) center;
        }

        #room-arrival {
            padding-right: pxToRem(2);
        }

        #room-departure {
            padding-left: pxToRem(2);
        }

        #room-adults {
            padding: pxToRem(4) 0;
        }
    }
}