.infobox {
    display: flex;
    flex-direction: column;

    &-inner {
        padding: pxToRem(48);
        color: $color-white;
        text-align: center;
        background-color: $color-grey-1100;
    }

    &__header {
        @include getFigmaStyle('Desktop/H4/Regular/Uppercase');
    }

    hr {
        width: 100%;
        height: 1px;
        margin: pxToRem(28) 0;
        background-color: $color-black;
    }

    &__facts ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: pxToRem(48);
        row-gap: pxToRem(16);
    }

    &__content {
        padding: 0 pxToRem(128);
    }
}

.infobox--gastro {
    .infobox__content {
        p:first-child {
            margin-bottom: pxToRem(8);
            @include getFigmaStyle('Desktop/MD/Bold/Default');
        }
    }
}

.infobox__content-headline {
    margin-bottom: pxToRem(8);
    @include getFigmaStyle('Desktop/MD/Bold/Default');
}

.booking-btn--infobox {
    //margin: pxToRem(48) auto 0;
    margin: 0 auto;
}

@include media-breakpoint-down(lg) {
    .infobox {
        &-inner {
            padding: pxToRem(48) pxToRem(20);
        }
        &__facts ul {
            column-gap: pxToRem(16);
        }

        &__content {
            padding: initial;
        }
    }
}

.infobox.infobox--gastro {
    .infobox__content, .infobox__facts {
        p:not(:first-child) {
            margin-top: pxToRem(28);
        }

        a {
            color: $color-white;
            text-decoration: underline;
        }
    }
}